import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Hidden, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Add, HorizontalRule, Remove } from '@mui/icons-material';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  ProductDetail, ProductDetailImage, ProductDetailBrandNameText, ProductDetailPackSizeText, ShowMd, ProductCounter,
  ProductDetailItemPriceText, ProductCounterButton, DotsButton,
  ProductDetailNameMainContainer, ProductCounterQuantityInput, GreySpan, IsHybrid,
  ProductDetailBrandTypo, ProductCounterWrapper, MinQtyTypo, BodyCell, ImageWrapper, MobileText, MobileButttonGrid, RemoveText,
  OutOfStockTypo, WarningText, ProductDetailFlex,
  DeliveryContainer
} from './styles';
import { PromoInfo } from '../../orders/newOrder/promoInfo';
import { useStateValue } from '../../../providers/stateProvider';
import { toTitleCase } from '../../../utils/toTitleCase';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { ProductTableRow } from '../cart.styles';
import ActionPopper from './actionPopper';
import SupplierDialog from './supplierDialog';
import { CostDeliveryIcon, DeleteFilledIcon, FreeDeliveryIcon } from '../../../assets/svgs';
import { JSONParse } from '../../../utils/json';

const ProductItem = ({
  product, platform, incrementProductQuantity, decrementProductQuantity, openDeleteDialog,
  changeInputQuantity, switchOrderProductSupplier, refetch
}) => {
  const {
    id, name: brandName, product: prod, quantity, hybrid, productVariant,
    alternativeSupplierName, manualCart, supplier, minimumOrderQuantity, 
    orderProductId, availableQty, orderProductStatus, image, packSize, deliveryInfo, isAvailable
  } = product;
  const isOutOfStock = availableQty === 0 || orderProductStatus === 'out_of_stock';
  // const { meta } = prodDetail;
  // const parsedMeta = JSONParse(meta);
  // const { product_variant: prodVariant } = parsedMeta;
  const isLessStock = !isOutOfStock && (quantity > availableQty);
  const disableItem = isOutOfStock || isLessStock;
  const isSmall = useMediaQuery('(max-width: 991px)');
  const isMarketPlace = platform === 'marketplace';
  const [dotsButtonEl, setDotsButtonEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [promo, setPromo] = useState(0);
  const [{ affiliate: { pricePolicyFactor } }] = Object.values(useStateValue());
  // const notAvailable = prodVariant === 'NOT_AVAILABLE';

  const productObject = new Product(product, platform);
  const unitPrice = platform === 'erp' ? productObject.orderCost : productObject.unitCost;
  const productPrice = Number(unitPrice * pricePolicyFactor);

  const handleAction = (event) => {
    setDotsButtonEl(dotsButtonEl ? null : event.currentTarget);
  };

  const handleDialog = () => {
    setDotsButtonEl(null);
    setOpenDialog(!openDialog);
  };

  const handleDeleteDialog = () => {
    setDotsButtonEl(null);
    openDeleteDialog(prod?.id);
  };

  const switchSupplier = (useAlternativeSupplier) => {
    switchOrderProductSupplier({
      quantity,
      productId: id,
      useAlternativeSupplier,
      isManualOrder: manualCart
    });
  };
  const renderAlternativeGuide = () => {
    if (!manualCart || (supplier === alternativeSupplierName)) {
      return null;
    }
    if (alternativeSupplierName.trim() === 'OGApharmacy') {
      return (
        <Grid>
          <GreySpan><u>Order from OGApharmacy</u></GreySpan>
        </Grid>
      );
    }
    return (
      <Grid>
        <i>
          {alternativeSupplierName}
          {' '}
          <GreySpan onClick={() => switchSupplier(true)} />
        </i>
      </Grid>
    );
  };
  const isHybrid = () => {
    if (manualCart && hybrid && hybrid === 'YES') return (<IsHybrid>Hybrid</IsHybrid>);
    return null;
  };

  const stat = deliveryInfo === 'FREE_DELIVERY' ? 'free' : 'charge';
  const renderDeliveryStatus = () => (
    <DeliveryContainer status={stat}>
      {stat === 'free' ? <FreeDeliveryIcon style={isSmall ? { width: '3rem' } : {}} /> : <CostDeliveryIcon /> }
      {stat === 'free' ? 'Free Delivery' : 'Delivery Fee Applies' }
    </DeliveryContainer>
  );

  return (
    <>
      <Hidden mdDown>
        <ProductTableRow prodNotAvailable={!isAvailable}>
          <ProductDetail container>
            <ProductDetailFlex>
              <ImageWrapper><ProductDetailImage src={image} alt="" loading="lazy" /></ImageWrapper>
              <ProductDetailNameMainContainer>
                {isHybrid()}
                {renderDeliveryStatus()}
                <ProductDetailBrandNameText>
                  { toTitleCase(brandName) }
                </ProductDetailBrandNameText>
                {isOutOfStock ? <OutOfStockTypo>Out of stock</OutOfStockTypo> : ''}
                {isLessStock ? <OutOfStockTypo>{`${availableQty} units left`}</OutOfStockTypo> : ''}
                <ProductDetailPackSizeText>
                  Pack Size:&nbsp;
                  { packSize }
                  {promo < 1 ? '' : <PromoInfo info="First Order Promotion" />}
                  {renderAlternativeGuide()}
                </ProductDetailPackSizeText>
                {(productVariant === 'onRequest')
                  ? (
                    <MinQtyTypo variant="body1">
                      Minimum Quantity:
                      {' '}
                      &nbsp;
                      {minimumOrderQuantity}
                    </MinQtyTypo>
                  ) : '' }
              </ProductDetailNameMainContainer>
            </ProductDetailFlex>
          </ProductDetail>
          <BodyCell>
            <ProductCounter>
              <ProductCounterButton
                onClick={() => decrementProductQuantity(product)}
                disabled={productVariant === 'onRequest' ? (quantity === minimumOrderQuantity) : isOutOfStock}
                bColor={isOutOfStock ? '#D7D7D7' : '#FAA9A3'}
                className="mpAdmin-uat-cartPage-addRemoveIcon"
              >
                <HorizontalRule style={{ fontSize: '18px' }} />
              </ProductCounterButton>

              <ProductCounterQuantityInput
                value={Number(quantity)}
                onChange={(event) => changeInputQuantity(product, event.target.value)}
                disabled={disableItem}
                className="mpAdmin-uat-cartPage-quantityInput"
                prodNotAvailable={!isAvailable}
              />

              <ProductCounterButton
                onClick={() => incrementProductQuantity(product)}
                bColor={disableItem ? '#D7D7D7' : '#A9D3AB'}
                disabled={disableItem}
                className="mpAdmin-uat-cartPage-addRemoveIcon"
              >
                <Add style={{ fontSize: '18px' }} />
              </ProductCounterButton>
            </ProductCounter>
            {isLessStock ? <WarningText container>Reduce quantity</WarningText> : ''}
          </BodyCell>
          <BodyCell>
            <ProductDetailItemPriceText disabled={disableItem}>
              {`₦${currencyFormatter(productPrice)}`}
            </ProductDetailItemPriceText>
          </BodyCell>
          <BodyCell>
            <ProductDetailItemPriceText disabled={disableItem}>
              {`₦${currencyFormatter(Number(productPrice) * Number(quantity))}`}
            </ProductDetailItemPriceText>
          </BodyCell>

          <BodyCell>
            {!isMarketPlace ? (
              <DotsButton onClick={handleAction}>...</DotsButton>
            )
              : (
                <Button
                  onClick={() => openDeleteDialog(prod?.id)}
                  className="mpAdmin-uat-cartPage-deleteCart"
                >
                  <DeleteFilledIcon />
                </Button>
              )}
          </BodyCell>

          <ActionPopper
            open={dotsButtonEl}
            close={() => setDotsButtonEl(null)}
            deleteAction={handleDeleteDialog}
            supplierAction={handleDialog}
          />

          <SupplierDialog
            productId={orderProductId}
            open={openDialog}
            close={handleDialog}
            refetchCart={refetch}
          />
        </ProductTableRow>
      </Hidden>
      <ShowMd container item prodNotAvailable={!isAvailable}>
        <Grid item container xs={4}>
          <ImageWrapper><ProductDetailImage src={image} alt="" loading="lazy" /></ImageWrapper>
        </Grid>
        <Grid item container xs={8} direction="column">
          <Grid item container style={{ marginBottom: isSmall && '1rem' }}>
            {renderDeliveryStatus()}
            <ProductDetailBrandTypo>
              {toTitleCase(brandName)}
            </ProductDetailBrandTypo>
          </Grid>
          <Grid item container style={{ marginBottom: isSmall && '1rem' }}>
            {isOutOfStock ? <OutOfStockTypo>Out of stock</OutOfStockTypo> : ''}
            {isLessStock ? <OutOfStockTypo>{`${availableQty} units left`}</OutOfStockTypo> : ''}
          </Grid>
          <Grid item container alignItems="center" style={{ marginBottom: isSmall && '1rem' }}>
            <Grid item container xs={12}>
              <ProductDetailPackSizeText>
                Pack Size:&nbsp;
                {packSize}
              </ProductDetailPackSizeText>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" style={{ width: '70%', marginBottom: isSmall && '1rem' }} justifyContent="space-between">
            <Grid item xs={6} md={4}>
              <MobileText>Unit Cost</MobileText>
              <ProductDetailItemPriceText disabled={isOutOfStock || isLessStock}>
                {`₦${currencyFormatter(productPrice)}`}
              </ProductDetailItemPriceText>
            </Grid>
            <Grid item xs={4} md={4}>
              <MobileText>Price</MobileText>
              <ProductDetailItemPriceText style={{ color: '#000' }} disabled={isOutOfStock || isLessStock}>
                {`₦${currencyFormatter(Number(productPrice) * Number(quantity))}`}
              </ProductDetailItemPriceText>
            </Grid>
          </Grid>
        </Grid>
        <MobileButttonGrid container>
          <ProductCounterWrapper>
            <ProductCounter>
              <ProductCounterButton
                onClick={() => decrementProductQuantity(product)}
                disabled={productVariant === 'onRequest' ? (quantity === minimumOrderQuantity) : isOutOfStock}
                bColor={isOutOfStock ? '#D7D7D7' : '#FAA9A3'}
              >
                <Remove style={{ fontSize: '3rem', color: '#235A91' }} alt="Remove" disabled={isOutOfStock} />
              </ProductCounterButton>
              <ProductCounterQuantityInput
                value={Number(quantity)}
                onChange={(event) => changeInputQuantity(product, event.target.value)}
                disabled={isOutOfStock}
              />
              <ProductCounterButton
                onClick={() => incrementProductQuantity(product)}
                disabled={disableItem}
                bColor={disableItem ? '#D7D7D7' : '#A9D3AB'}
              >
                <Add style={{ fontSize: '3rem', color: '#235A91' }} alt="Add" disabled={disableItem} />
              </ProductCounterButton>
            </ProductCounter>
            {isLessStock ? <WarningText container>Reduce quantity</WarningText> : ''}
          </ProductCounterWrapper>
          <div>
            <Button
              onClick={() => openDeleteDialog(prod?.id)}
              className="mpAdmin-uat-cartPage-deleteCart"
            >
              <DeleteFilledIcon style={{ fontSize: '3rem' }} />
              <RemoveText>Remove</RemoveText>
            </Button>
          </div>
        </MobileButttonGrid>
      </ShowMd>
    </>
  );
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  platform: PropTypes.string,
  incrementProductQuantity: PropTypes.func.isRequired,
  decrementProductQuantity: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  switchOrderProductSupplier: PropTypes.func.isRequired,
  changeInputQuantity: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

ProductItem.defaultProps = {
  platform: 'marketplace'
};

export default ProductItem;
